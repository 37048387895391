import React, { useEffect, useState } from "react";
import { getData } from "../../Tools/helpers";

import FormCustom from "../../components/FormCustom";
import TableCustom from "../../components/TableCustom";

const FourMonth = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [isTrueUpdateData, setIsTrueUpdateData] = useState(false);
  const [isTrueDeleteData, setIsTrueDeleteData] = useState(false);

  const FieldsCustom = [
    { name: "monthly_payment", label: "Costo Colegiatura", required: "true", type: "number" },
    { name: "surcharge_payment", label: "Costo recargo por día", required: "true", type: "number" },
    { name: "inscription_payment", label: "Costo inscripción", required: "false", type: "number" },
    { name: "prefix_bill", label: "Prefijo factura", required: "false" },
    { name: "cp_bill_api", label: "Código postal facturación", required: "false", type: "number" },
  ];

  const routeForm = "GlobalConfig";

  const getConfig = async () => {
    const route = routeForm;
    const send = await getData(route);
    if (send.code === 200) {
      setDataTable(send.data);
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <div className="flex-1">
      <div className=" mb-10 max-w-2xl">
        <FormCustom
          fields={FieldsCustom}
          title="CONFIGURACIONES GENERALES"
          route={routeForm}
          method="post"
          setData={setDataForm}
          data={dataForm}
          fieldDialog="name"
          setIsTrueUpdateData={setIsTrueUpdateData}
          isTrueUpdateData={isTrueUpdateData}
          refreshDataList={getConfig}
          setIsTrueDeleteData={setIsTrueDeleteData}
          isTrueDeleteData={isTrueDeleteData}
        />
      </div>
      <div className="flex-1">
        <TableCustom
          fields={FieldsCustom}
          setDataForm={setDataForm}
          data={dataTable}
          setIsTrueUpdateData={setIsTrueUpdateData}
          setIsTrueDeleteData={setIsTrueDeleteData}
        />
      </div>
    </div>
  );
};

export default FourMonth;
