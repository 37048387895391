import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [user, setUser] = useState({});

  const navigate = useNavigate();
  const checkSession = async () => {
    const user = await localStorage.getItem("USER_IUMV");
    setUser(JSON.parse(user));
    if (!user) {
      navigate("/Login");
      return;
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <div
      className="flex items-center flex-wrap p-3 px-10 header"
      style={{ backgroundImage: `url("banner_iumv.jpg")` }}
    >
      <div className="flex items-center flex-shrink-0 text-white mr-6 hidden lg:block">
        <Link to="/">
          <img src="https://intranet.iumv.edu.mx/logo_iumv.png" alt="IUMV" />
        </Link>
      </div>
      <div className="text-left hidden lg:block">
        <p className="text-xs text-gray-500">
          <label className="font-semibold">CURP:</label> {user.curp}
        </p>
        <p className="text-xs text-gray-500">
          <label className="font-semibold">Nombre:</label> {user.name}
        </p>
        <p className="text-xs text-gray-500">
          <label className="font-semibold">Campus:</label> {user.university_name}
        </p>
        {user.type === "staff" ? (
          <Fragment>
            <p className="text-xs text-gray-500">
              <label className="font-semibold">Alianza:</label>
            </p>
            <p className="text-xs text-gray-500">
              <label className="font-semibold">Puesto:</label> {user.description_position}
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <p className="text-xs text-gray-500">
              <label className="font-semibold">Carrera:</label> {user.career_name}
            </p>
            <p className="text-xs text-gray-500">
              <label className="font-semibold">Fecha de inicio:</label> {user.start_date}
            </p>
            <p className="text-xs text-gray-500">
              <label className="font-semibold">Fecha de término:</label> {user.end_date}
            </p>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Header;
