
// const baseRuta = "http://127.0.0.1:8000/api/";
const baseRuta = "https://intranet-api.iumv.edu.mx/api/";
// const baseRuta = "https://iumv-api.spimexicocorporativo.com/api/";

export async function insertData(ruta, objeto) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_IUMV");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        //'Content-Type': 'multipart/form-data'
      },
      body: objeto,
    };
    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function insertDataJson(ruta, objeto) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_IUMV");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objeto),
    };
    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function getData(ruta) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_IUMV");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function updateItem(ruta, objeto) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_IUMV");
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objeto),
    };

    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "danger", message: "Ha ocurrido algo:" + err };
  }
}

export async function deleteItem(ruta) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_IUMV");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "danger", message: "Ha ocurrido algo:" + err };
  }
}

export async function login(ruta, objeto) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objeto),
    };
    let response = await fetch(baseRuta + ruta, requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function insertActivity(usuario, seccion, actividad, color) {
  try {
    const token = localStorage.getItem("STORAGE_KEY_IUMV");

    let formData = new FormData();
    formData.append("desc_1", seccion);
    formData.append("desc_2", color);
    formData.append("usuario", usuario);
    formData.append("seccion", actividad);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        //'Content-Type': 'multipart/form-data'
      },
      body: formData,
    };
    let response = await fetch(baseRuta + "actividad", requestOptions);
    let data = await response.json();
    return data;
  } catch (err) {
    return { status: "error", message: "Ha ocurrido algo:" + err };
  }
}

export async function calcularTotal(venta, setVenta, detalleventa) {
  const sumaPrecios = await detalleventa.reduce(
    (prev, next) => prev + parseFloat(next.importe_partida),
    0
  );
  console.log(sumaPrecios);
  if (sumaPrecios > 0) {
    await setVenta({
      ...venta,
      subtotal: sumaPrecios,
      iva: parseFloat(sumaPrecios * 0.16),
      total: parseFloat(sumaPrecios * 1.16),
    });
  }
}

export async function searchTable(list, item, field, field2, field3) {
  item = item.replace(/\s/g, '');
  console.log(item.replace(/\s/g, ''))
  const resultado = list.filter((resul) => {
    if (resul[field]) {
    } else {
      resul[field] = "";
    }
    if (resul[field2]) {
    } else {
      resul[field2] = "";
    }
    if (resul[field3]) {
    } else {
      resul[field3] = "";
    }

    if (
      resul[field]
        .replace(/\s/g, '')
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(item.toLowerCase()) ||
      resul[field2]
      .replace(/\s/g, '')
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(item.toLowerCase()) ||
      resul[field3]
        .replace(/\s/g, '')
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(item.toLowerCase())
    ) {
      return resul;
    }
  });
  return resultado;
}

export function restarFecha(fecha1, fecha2) {
  let fecha = Math.abs(new Date(fecha2) - new Date(fecha1));
  let minutos = Math.floor(fecha / 1000 / 60);
  let total_tiempo_min = minutos;
  let tipo = "min";
  if (minutos >= 60) {
    minutos = (minutos / 60).toFixed(2);
    tipo = "hrs";
  }
  let datos = {
    tiempo: minutos,
    tipo: tipo,
    total_tiempo_min: total_tiempo_min,
  };
  return datos;
}

export function getDateNow() {
  var dateNow = new Date();
  const year = dateNow.getFullYear();
  let month = dateNow.getMonth();
  let day = dateNow.getDate();
  const locale = "es-MX";
  const monthDescription = dateNow.toLocaleString(locale, { month: "long" });
  month = dateNow.toLocaleDateString("es-MX",{ month:'numeric'});

  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  
  return {
    year: year,
    month: month,
    day: day,
    fullDate: `${year}-${month}-${day}`,
    monthDescription: monthDescription,
  };
}

export function getTimeNow(date) {
  let dateFull =
    new Date(date).getHours() +
    ":" +
    new Date(date).getMinutes() +
    ":" +
    new Date(date).getSeconds();
  return dateFull;
}
