import React, { useState, useEffect } from "react";
import { updateItem, getData } from "../Tools/helpers";
import Alert from "@mui/material/Alert";

import TableHistoryCustom from "../components/TableHistoryCustom";
import DialogCustom from "../components/DialogCustom";

const RequestDischarge = () => {
  const [data, setData] = useState([]);
  const [itemApproved, setItemApproved] = useState(0);
  const [isDialog, setIsDialog] = useState(false);
  const [isDialog2, setIsDialog2] = useState(false);
  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const FieldsCustomTable = [
    { name: "id", label: "ID" },
    { name: "name", label: "Campus" },
    { name: "description", label: "Concepto" },
    { name: "type", label: "Tipo" },
    { name: "type_request", label: "Ingreso/Egreso" },
    { name: "register_date", label: "Fecha" },
    { name: "amount", label: "Importe" },
    { name: "segment", label: "Segmento" },
    { name: "method_payment", label: "Forma de pago" },
    { name: "status_description", label: "Estatus" },
    { name: "approved_by", label: "Apropado por" },
    { name: "approved_date", label: "Fecha aprobación" },
    { name: "status", label: "" },
  ];

  const getDischarges = async () => {
    const route = "Discharge";
    const send = await getData(route);
    if (send.code === 200) {
      getEntry(send.data);
    }
  };

  const getEntry = async (dataDischarges) => {
    const route = "Entry";
    const send = await getData(route);
    if (send.code === 200) {
      const newData = dataDischarges.concat(send.data);
      setData(newData);
    }
  };

  const approvedDischarge = async (option, statusPayment) => {
    setMessage({
      status: false,
      message: "",
      label: "",
    });

    if(statusPayment === 0){
      setIsDialog(false);
    }else{
      setIsDialog2(false);
    }

    if (!option) {
      return;
    }
    setMessage({
      status: true,
      message: "Cancelando...",
      label: "info",
    });

    const dataStatus = {
      status: statusPayment,
      status_description: statusPayment === 0 ? "Aprobado" : "Declinado",
    };

    const route = itemApproved.type_request === 'Egreso' ? 'Discharge' : 'Entry';

    const send = await updateItem(`${route}/${itemApproved.id}`, dataStatus);
    if (send.code === 200) {
      setMessage({
        status: true,
        message: "Se ha aprobado correctamente",
        label: "success",
      });
      getDischarges();
      return;
    }
    setMessage({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  };

  const activeDialog = (row, action) => {
    setItemApproved(row);
    if(action === 'aprobar'){
      setIsDialog(true);
      return;
    }
    setIsDialog2(true);
  };

  useEffect(() => {
    getDischarges();
  }, []);

  return (
    <div className="flex-1">
      <DialogCustom
        open={isDialog}
        message={{ title: "Aprobar", message: "¿Desea aprobar la solicitud?" }}
        action={approvedDischarge}
        statusPayment = {0}
      />
        <DialogCustom
        open={isDialog2}
        message={{ title: "Declinar", message: "¿Desea declinar la solicitud?" }}
        action={approvedDischarge}
        statusPayment = {2}
      />
      <div className="flex-1 mb-10">
        <div className="my-10">
          <p className="text-4xl underline">Solicitudes de Ingreso/Egreso</p>
        </div>
        {message.status ? (
          <Alert className="mt-2" severity={message.label}>
            {message.message}
          </Alert>
        ) : (
          ""
        )}
        <TableHistoryCustom
          fields={FieldsCustomTable}
          data={data}
          actionType={["approve"]}
          action={activeDialog}
        />
      </div>
    </div>
  );
};

export default RequestDischarge;
