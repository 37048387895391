import React, { useEffect, useState } from "react";
import {   
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement,   CategoryScale,
  LinearScale,
  BarElement,
  Title, Tooltip, Legend);

const TotalEntry = ({ totalEntry }) => {

  const options = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    responsive: true,
    height: 300,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pagos/Adeudos Campus',
      },
    },
  };

  const labels = totalEntry ? totalEntry.universities : [];

  const dataEntryTotal = {
    labels,
    datasets: [
      totalEntry.payments ? totalEntry.payments : [],
      totalEntry?.debts ? totalEntry?.debts : [],
    ] 
  };

  return (
    <div className="p-10">
      <Bar options={options} data={dataEntryTotal} />
    </div>
  );
};

export default TotalEntry;
