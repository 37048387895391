import React, { Fragment, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Nav from "./Template/Nav";
import Header from "./Template/Header";

import Notification from "./Home/Notification";
import PaymentContainer from "./Payment/PaymentContainer";
import Entry from "./Entry/Entry";
import Discharge from "./Discharge/Discharge";
import RequestDischarge from "./RequestDischarge/RequestDischarge";
import RequestPaymentTransfer from './RequestPaymentTransfer/RequestPaymentTransfer'
import Dashboard from "./Dashboard/Dashboard";
import Admin from "./Admin/AdminContainer";
import Report from "./Report/Report"
import Activity from "./Activity/Activity";
import HistoryPayments from "./HistoryPayments/HistoryPayments";

const Container = () => {
  const [userData] = useState(JSON.parse(localStorage.getItem("USER_IUMV")));

  const directorGeneralRoute = [
    {
      active: 0,
      name: "Inicio",
      key: "Dashboard",
      route: "Dashboard",
      component: <Dashboard />,
    },
    {
      active: 5,
      name: "Notificaciones",
      key: "Notification",
      route: "Notification",
      component: <Notification />,
    },
    {
      active: 1,
      name: "Pagos",
      key: "Payment",
      route: "Payment/*",
      component: <PaymentContainer />,
    },
    {
      active: 2,
      name: "Entrega de Efectivo",
      key: "Entry",
      route: "Entry",
      component: <Entry />,
    },
    {
      active: 3,
      name: "Egreso",
      key: "Discharge",
      route: "Discharge",
      component: <Discharge />,
    },
    {
      active: 4,
      name: "Solicitudes Ingreso/Egreso",
      key: "Request",
      route: "Request",
      component: <RequestDischarge />,
    },
    {
      active: 8,
      name: "Válidar Pagos",
      key: "RequestTransfer",
      route: "RequestTransfer",
      component: <RequestPaymentTransfer />, 
    },
    {
      active: 6,
      name: "Admin",
      key: "Admin",
      route: "Admin/*",
      component: <Admin />,
    },
    {
      active: 7,
      name: "Actividad",
      key: "Activity",
      route: "Activity",
      component: <Activity />,
    },
    {
      active: 9,
      name: "Reporte",
      key: "Report",
      route: "Report",
      component: <Report />,
    },
  ];

  const cajeroRoute = [
    {
      active: 5,
      name: "Inicio",
      key: "Notification",
      route: "Notification",
      component: <Notification />,
    },
    {
      active: 1,
      name: "Pagos",
      key: "Payment",
      route: "Payment/*",
      component: <PaymentContainer />,
    },
    {
      active: 2,
      name: "Entrega de Efectivo",
      key: "Entry",
      route: "Entry",
      component: <Entry />,
    },
    {
      active: 3,
      name: "Egreso",
      key: "Discharge",
      route: "Discharge",
      component: <Discharge />,
    },
    {
      active: 10,
      name: "Historial Pagos",
      key: "HistoryPayments",
      route: "HistoryPayments",
      component: <HistoryPayments />,
    },
    {
      active: 9,
      name: "Reporte",
      key: "Report",
      route: "Report",
      component: <Report />,
    },
  ];

  const cajeroRoutePagos = [
    {
      active: 5,
      name: "Inicio",
      key: "Notification",
      route: "Notification",
      component: <Notification />,
    },
    {
      active: 1,
      name: "Pagos",
      key: "Payment",
      route: "Payment/*",
      component: <PaymentContainer />,
    },
    {
      active: 2,
      name: "Entrega de Efectivo",
      key: "Entry",
      route: "Entry",
      component: <Entry />,
    },
    {
      active: 3,
      name: "Egreso",
      key: "Discharge",
      route: "Discharge",
      component: <Discharge />,
    },
    {
      active: 8,
      name: "Válidar Pagos",
      key: "RequestTransfer",
      route: "RequestTransfer",
      component: <RequestPaymentTransfer />, 
    },
    {
      active: 10,
      name: "Historial Pagos",
      key: "HistoryPayments",
      route: "HistoryPayments",
      component: <HistoryPayments />,
    },
  ];

  const studentRoute = [
    {
      active: 0,
      name: "Inicio",
      key: "Notification",
      route: "Notification",
      component: <Notification />,
    },
    {
      active: 1,
      name: "Pagos",
      key: "Payment",
      route: "Payment/*",
      component: <PaymentContainer />,
    },
  ];

  const getRoutesCustom = () => {
    let routeCustom = studentRoute;

    if (
      userData.abbreviation === "director_general" ||
      userData.abbreviation === "director" ||
      userData.abbreviation === "gerente" ||
      userData.abbreviation === "gerente_general" ||
      userData.abbreviation === "rector"
    ) {
      return (routeCustom = directorGeneralRoute);
    } else if (userData.abbreviation === "cajero") {
      return (routeCustom = cajeroRoute);
    }else if (userData.abbreviation === "cajero_pagos") {
      return (routeCustom = cajeroRoutePagos);
    }
    return routeCustom;
  };

  return (
    <Fragment>
      <Header />
      <Nav routes={getRoutesCustom()} />
      <div className="flex flex-col px-5">
        <Routes>
          <Route exact path="/*" element={userData.abbreviation !== "director_general" ? <Notification /> : <Dashboard />} />
          {getRoutesCustom().map((route) => (
            <Route exact path={route.route} element={route.component} key={route.key} />
          ))}
        </Routes>
      </div>
    </Fragment>
  );
};

export default Container;
