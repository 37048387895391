import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { deleteItem, insertDataJson, updateItem } from "../Tools/helpers";

import DialogCustom from "./DialogCustom";

const FormCustom = ({
  fields,
  title,
  route,
  data,
  setData,
  fieldDialog,
  setIsTrueUpdateData,
  isTrueUpdateData,
  refreshDataList,
  setIsTrueDeleteData,
  isTrueDeleteData,
}) => {
  const [required, setRequired] = useState([]);
  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const [messageDialog, setMessageDialog] = useState({
    title: "",
    message: "",
  });

  const [userData] = useState(JSON.parse(localStorage.getItem("USER_IUMV")));

  const setState = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    let requiredInput = [];
    setRequired([]);

    fields.map((item) => {
      if (
        (data[item.name] === "" && item.required === "true") ||
        (data[item.name] === undefined && item.required === "true")
      ) {
        console.log(data["password"]);
        console.log(!isTrueUpdateData);
        if (
          (item.name === "password" && isTrueUpdateData) ||
          (data["password"] === undefined && isTrueUpdateData)
        ) {
          return;
        }
        requiredInput.push(item.label);
      }
    });

    setRequired(requiredInput);
    if (requiredInput.length > 0) {
      return;
    }

    setMessage({
      status: true,
      message: "Guardando...",
      label: "info",
    });
    let send;

    if (isTrueUpdateData) {
      send = await updateItem(`${route}/${data.id}`, data);
    } else {
      send = await insertDataJson(route, data);
    }

    if (send.code === 201 || send.code === 200) {
      setMessage({
        status: true,
        message: "Se ha guardado correctamente",
        label: "success",
      });
      setIsTrueUpdateData(false);
      refreshDataList();
      setData([]);
      return;
    }

    if (send.code === 401) {
      setMessage({
        status: true,
        message: "No tiene permiso para realizar esta acción",
        label: "error",
      });
      setIsTrueUpdateData(false);
      refreshDataList();
      setData([]);
      return;
    }

    if (send.errors) {
      Object.values(send.errors).map((item) => {
        setMessage({
          status: true,
          message: item,
          label: "error",
        });
      });
      return;
    }

    setMessage({
      status: true,
      message: "Ha ocurrido algo",
      label: "error",
    });
  };

  const deleteData = async (option) => {
    setIsTrueDeleteData(false);
    console.log(option);
    if (option) {
      const send = await deleteItem(`${route}/${data.id}`);
      console.log(send);
      if (send.code === 200) {
        setMessage({
          status: true,
          message: "Eliminado correctamente",
          label: "success",
        });
        refreshDataList();
        setData([]);
        return;
      }

      if (send.code === 401) {
        setMessage({
          status: true,
          message: "No tiene permiso para realizar esta acción",
          label: "error",
        });
        setIsTrueUpdateData(false);
        setData([]);
        return;
      }

      setMessage({
        status: true,
        message: "Ha ocurrido algo o el elemento se está usando",
        label: "error",
      });
    }
  };

  useEffect(() => {
    if (isTrueDeleteData) {
      console.log(data);
      setMessageDialog({
        title: "¿Esta seguro que desea eliminar?",
        message: data[fieldDialog],
      });
    }
  }, [isTrueDeleteData]);
  if (userData) {
    if (userData.abbreviation === "rector" && route !== "Notification") {
      return null;
    }
  }
  return (
    <form className="mt-6" onSubmit={submitForm}>
      <DialogCustom message={messageDialog} action={deleteData} open={isTrueDeleteData} />
      <p className="font-bold">{title}</p>
      {fields.map((item) => {
        return item.type === "select" ? (
          <TextField
            fullWidth
            sx={{ m: 1 }}
            key={item.name}
            id={item.name}
            name={item.name}
            label={item.required === "true" ? `${item.label}*` : item.label}
            value={data[item.name] ? data[item.name] : 0}
            select
            SelectProps={{
              native: true,
            }}
            onChange={setState}
          >
            <option value="0">Seleccione una opción</option>
            {item.list.map((option) => (
              <option key={option.id} value={option.id}>
                {option[item["field_select_description"]]}
              </option>
            ))}
          </TextField>
        ) : (
          <TextField
            fullWidth
            sx={{ m: 1 }}
            key={item.name}
            type={item.type ?? item.type}
            size="small"
            id={item.name}
            name={item.name}
            label={item.required === "true" ? `${item.label}*` : item.label}
            InputLabelProps={{  shrink: true }}
            key={item.name}
            value={data[item.name] ? data[item.name] : ""}
            variant="outlined"
            onChange={setState}
          />
        );
      })}
      <div className="mt-6">
        <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
          {isTrueUpdateData ? "Actualizar" : "Guardar"}
        </button>
      </div>
      <div>
        {message.status ? (
          <Alert className="mt-2" severity={message.label}>
            {message.message}
          </Alert>
        ) : (
          ""
        )}
      </div>
      <div className="">
        <div className="mt-2 peer-invalid:visible text-pink-600 text-sm">
          {required.length > 0 ? (
            <div>
              <p className="font-bold">Requerido:</p>
              {required.map((item) => (
                <p key={item}>- {item}*</p>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormCustom;
