import React, { useState, useEffect } from "react";
import { getDateNow, insertDataJson, getData } from "../Tools/helpers";

import TotalEntry from "./Charts/TotalEntry";
import Payment from "./Charts/Payment";
import AditionalEntry from "./Charts/AditionalEntry";
import PaymentOptions from "./Charts/PaymentOptions";
import AditionalsPaymentOthers from "./Charts/AditionalsPaymentOthers";
import Student from "./Charts/Students";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import AllCareers from "./Charts/AllCareers";
import AllFourMonth from "./Charts/AllFourMonth";

const Dashboard = () => {
const defaultDate = getDateNow().year + '-01-01';
const [totalEntry, setTotalEntry] = useState([]);
const [totalDebts, setTotalDebts] = useState({});
const [totalDischarge, setTotalDischarge] = useState({});
const [paymentOptions, setPaymentOptions] = useState([]);
const [aditionalPayment, setAditionalPayment] = useState([]);
const [totalStudents, setTotalStudents] = useState({});
const [totalAllCareer, setTotalAllCareer] = useState([]);
const [totalAllFourMont, setTotalAllFourMont] = useState([]);
const [listUniversities, setListUniversities] = useState([]);
const [IDuniversity, setIDUniversity] = useState(0);
const [consulting, setConsulting] = useState(true);
const [methodPayment, setMethodPayment] = useState(0);
const [year, setYear] = useState(new Date().getFullYear());
const [listYear, setListYear] = useState([]);
const [dates, setDates] = useState({
  init_date: defaultDate,
  end_date: getDateNow().fullDate,
  university_id: 0
  });
  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const getDataToDashboard = () =>{
    setConsulting(false);
    getTotalEntry();
    getDebts();
    getTotalStudents(year);
    getDischarges();
    getPaymentOptions();
    getAditionalPaymentOthers();
    getTotalAllCareer();
    getTotalAllFourMonth();
  }

  const getTotalEntry = async () => {
    const route = "TotalEntry";
    const send = await insertDataJson(route, dates);
    if (send.code === 200) {
      setTotalEntry(send.data);
    }
  };

  const getDebts = async () => {
    const route = "TotalDebts";
    const send = await insertDataJson(route, dates);
    if (send.code === 200) {
      setTotalDebts(send.data);
    }
  };

  const getDischarges = async (method) => {

    const newdata = {
      ...dates,
      method: method,
    }

    const route = "TotalDischarges";
    const send = await insertDataJson(route, newdata);
    if (send.code === 200) {
      setTotalDischarge(send.data);
    }
  };

  const getPaymentOptions = async () => {
    const route = "TotalPaymentOptions";
    const send = await insertDataJson(route, dates);
    if (send.code === 200) {
      setPaymentOptions(send.data);
    }
  };

  const getAditionalPaymentOthers = async () => {
    const route = "TotalAditionalPaymentOthers";
    const send = await insertDataJson(route, dates);
    if (send.code === 200) {
      setAditionalPayment(send.data);
    }
  };

  const getTotalAllCareer = async () => {
    const route = "TotalAllCareer";
    const send = await insertDataJson(route, dates);
    if (send.code === 200) {
      setTotalAllCareer(send.data);
    }
  };

  const getTotalAllFourMonth = async () => {
    const route = "TotalAllFourMonth";
    const send = await insertDataJson(route, dates);
    if (send.code === 200) {
      setTotalAllFourMont(send.data);
      console.log(send.data)
    }
  };

  const getTotalStudents = async (year) => {
    const data = {
      year: year,
      university_id: dates.university_id
    }
    const route = "TotalStudents";
    const send = await insertDataJson(route, data);
    if (send.code === 200) {
      setTotalStudents(send.data);
      setConsulting(false);
    }
  }

  const getUniversities = async () => {
    const route = "University";
    const send = await getData(route);
    if (send.code === 200) {
      setListUniversities(send.data);
    }
  };

  const toListYear = () => {
    const year = new Date().getFullYear();
    let years = [];
    for (let i = year - 5; i <= year; i++) {
      years.push(i);
    }
    setListYear(years);
  };

  const changeMethod = (e) => {
    setMethodPayment(e.target.value);
    getDischarges(e.target.value);
  };

  const changeYear = (e) => {
    setYear(e.target.value);
    getTotalStudents(e.target.value);
  };

  const setState = (e) => {
    setDates({
      ...dates,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    toListYear();
    getUniversities();
    getTotalEntry();
    getDebts();
    getDischarges();
    getPaymentOptions();
    getAditionalPaymentOthers();
    getTotalStudents(year);
    getTotalAllCareer();
    getTotalAllFourMonth();
  }, []);

  return (
    <div className="flex items-center justify-center ">
      <div className="flex flex-col justify-center items-center">
      <div className="my-10">
            <p className="text-4xl underline">Rango de consulta</p>
          </div>
          <div className="grid grid-cols-4 mb-10">
            <div className="mr-20">
            <TextField
                fullWidth
                sx={{ m: 1 }}
                type="date"
                size="small"
                id="init_date"
                name="init_date"
                label="Fecha inicio"
                InputLabelProps={{  shrink: true }}
                value={dates.init_date}
                variant="outlined"
                onChange={setState}
              />
            </div>
            <div className="mr-20">
                <TextField
                  fullWidth
                  sx={{ m: 1 }}
                  type="date"
                  size="small"
                  id="end_date"
                  name="end_date"
                  label="Fecha fin"
                  InputLabelProps={{  shrink: true }}
                  value={dates.end_date}
                  variant="outlined"
                  onChange={setState}
                />
            </div>
            <div className="mr-20">
              <TextField
              sx={{ m: 1 }}
              size="small"
              label="Campus"
              id={IDuniversity.university_id}
              name="university_id"
              value={IDuniversity.university_id}
              select
              SelectProps={{
                native: true,
              }}
              onChange={setState}
            >
              <option value="0">Todos los campus</option>
              {listUniversities.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </TextField>
            </div>
            <div className="mr-20">
              <button onClick={()=>getDataToDashboard()} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
                Buscar
              </button>
            </div>
            {message.status ? (
              <Alert className="mt-2" severity={message.label}>
                {message.message}
              </Alert>
            ) : (
              ""
            )}
          </div>
        <div>
          <p>{ consulting ? 'Consultando...' : null}</p>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 py-10 bg-white rounded-t-md bg-opacity-50">
          <div className=" justify-center items-center text-center col-span-3 font-bold text-2xl ">PAGOS Y ADEUDOS</div>
          <Payment totalDebts = {totalDebts} />
          <PaymentOptions paymentOptions = {paymentOptions} />
          <TotalEntry totalEntry = {totalEntry} />
          <AllCareers totalAllCareer = {totalAllCareer} />
          <AllFourMonth totalAllFourMont = {totalAllFourMont} />
          <AditionalsPaymentOthers aditionalPayment = {aditionalPayment} />
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 py-10 mt-10 bg-white rounded-t-md bg-opacity-50">
          <div className=" justify-center items-center text-center col-span-3 font-bold text-2xl ">ADMINISTRACIÓN</div>
          <div className="w-full flex col-span-3 items-center justify-center mt-10">
            <select
              className="px-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
              name="method_payment"
              value={methodPayment}
              onChange={(e)=>changeMethod(e)}
            >
              <option value="0">Seleccione una opción</option>
              <option value="Efectivo">Efectivo</option>
              <option value="Tarjeta">Tarjeta</option>
              <option value="Deposito">Deposito</option>
              <option value="Transferencia">Transferencia</option>
            </select>
          </div>
            <AditionalEntry totalDischarge = {totalDischarge} />
          <div className="w-full flex col-span-3 items-center justify-center mt-10">
            <TextField
              sx={{ m: 1 }}
              id="years"
              name="years"
              label="Año"
              value={year}
              size="small"
              select
              SelectProps={{
                native: true,
              }}
              onChange={(e) => changeYear(e)}
            >
              <option value="0">Todos</option>
              {listYear.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </div>
          <Student totalStudents = {totalStudents} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
