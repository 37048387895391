import React, { useState, useEffect } from "react";
import { getDateNow, insertDataJson, getData } from "../Tools/helpers";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { CSVLink } from "react-csv";

import TableHistoryCustom from "../components/TableHistoryCustom";

const Report = () => {
  const [data, setData] = useState([]);
  const [dates, setDates] = useState({
    init_date: getDateNow().fullDate,
    end_date: getDateNow().fullDate
  });
  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });
  const [subtotal, setSubtotal] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [cash, setCash] = useState(0);
  const [online, setOnline] = useState(0);
  const [total, setTotal] = useState(0);
  const [listUniversities, setListUniversities] = useState([]);
  const [IDuniversity, setIDUniversity] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState(0);
  const [listDebtsFourMonth, setListDebtsFourMonth] = useState([]);
  const [debts, setDebts] = useState(false);

  const getPaymentReport = async () => {
    setDebts(false);
    setMessage({
      status: true,
      message: "Consultando...",
      label: "info",
    });
    
    const route = "Report";
    const send = await insertDataJson(route, dates);
    if (send.code === 200) {
      setData(send.data);

      const total = send.data.reduce((accumulator, actual) => {
          return accumulator + parseFloat(actual.total ? actual.total : 0);
      }, 0);
      
      const transfer = send.data.reduce((accumulator, actual) => {
        if (actual.method_payment === 'Transferencia') {
          return accumulator + parseFloat(actual.total ? actual.total : 0);
        }
        return accumulator;
      }, 0);

      const cash = send.data.reduce((accumulator, actual) => {
        if (actual.method_payment === 'Efectivo' || actual.method_payment === 'EFECTIVO') {
          return accumulator + parseFloat(actual.total ? actual.total : 0);
        }
        return accumulator;
      }, 0);

      const online = send.data.reduce((accumulator, actual) => {
        if (actual.method_payment === 'En linea') {
          return accumulator + parseFloat(actual.total ? actual.total : 0);
        }
        return accumulator;
      }, 0);

      setTransfer((transfer.toLocaleString("en")));
      setCash((cash.toLocaleString("en")));
      setOnline((online.toLocaleString("en")));
      setTotal((total.toLocaleString("en")));
      setMessage({
        status: false,
        message: "",
        label: "",
      });
      return;
    }

    setMessage({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  };

  const getPaymentReportFourMonth = async() =>{
    setMessage({
      status: true,
      message: "Consultando deudores...",
      label: "info",
    });
    setDebts(true);
    setListDebtsFourMonth([]);
    const route = "TotalAllFourMonth";
    const newData = {
      ...dates,
      four_month: 1
    }
    const send = await insertDataJson(route, newData);
    if (send.code === 200) {

      const totalListNewData = [];
      for (const key in send.data.listDebtsByStudents) {
        const listNewData = [];
        if (send.data.listDebtsByStudents.hasOwnProperty(key)) {
          let newData = {};
          send.data.listDebtsByStudents[key].map(item=>{
            newData = {
              name: item
            }
            listNewData.push(newData);
          })
          totalListNewData[key] = listNewData;
        }
      }
      setListDebtsFourMonth(totalListNewData);
      setMessage({
        status: false,
        message: "",
        label: "",
      });
    }
  }

  const getUniversities = async () => {
    const route = "University";
    const send = await getData(route);
    if (send.code === 200) {
      setListUniversities(send.data);
    }
  };

  const FieldsCustomTable = [
    { name: "name_student", label: "Nombre" },
    { name: "description", label: "Concepto" },
    { name: "four_month_description", label: "Cuatrimestre" },
    { name: "month_description", label: "Mes" },
    { name: "year", label: "Año" },
    { name: "career_name", label: "Carrera" },
    { name: "university_name", label: "Campus" },
    { name: "amount", label: "Costo" },
    { name: "surcharge", label: "Recargo" },
    { name: "discount", label: "Descuento" },
    { name: "total", label: "Total" },
    { name: "user_name", label: "Cobró" },
    { name: "register_date", label: "Fecha" },
    { name: "method_payment", label: "Forma de pago" },
  ];

  const headers_report = [
    {key: "name_student", label: "Nombre" },
    {key: "description", label: "Concepto" },
    {key: "four_month_description", label: "Cuatrimestre" },
    {key: "month_description", label: "Mes" },
    {key: "year", label: "Año" },
    {key: "career_name", label: "Carrera" },
    {key: "university_name", label: "Campus" },
    {key: "amount", label: "Costo" },
    {key: "surcharge", label: "Recargo" },
    {key: "discount", label: "Descuento" },
    {key: "total", label: "Total" },
    {key: "user_name", label: "Cobró" },
    {key: "register_date", label: "Fecha" },
    {key: "method_payment", label: "Forma de pago" },
  ];


  const setState = (e) => {
    setDates({
      ...dates,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getPaymentReport();
    getUniversities();
  }, []);

  return (
    <div className="flex-1">
      <div className="my-10">
        <p className="text-4xl underline">Reporte de pagos</p>
      </div>
      <div className="grid grid-cols-6 mb-10">
        <div className="mr-10">
        <TextField
            fullWidth
            sx={{ m: 1 }}
            type="date"
            size="small"
            id="init_date"
            name="init_date"
            label="Fecha inicio"
            InputLabelProps={{  shrink: true }}
            value={dates.init_date}
            variant="outlined"
            onChange={setState}
          />
        </div>
        <div className="mr-10">
            <TextField
              fullWidth
              sx={{ m: 1 }}
              type="date"
              size="small"
              id="end_date"
              name="end_date"
              label="Fecha fin"
              InputLabelProps={{  shrink: true }}
              value={dates.end_date}
              variant="outlined"
              onChange={setState}
            />
        </div>
        <div>
          <TextField
            sx={{ m: 1 }}
            size="small"
            label="Campus"
            id={IDuniversity.university_id}
            name="university_id"
            value={IDuniversity.university_id}
            select
            SelectProps={{
              native: true,
            }}
            onChange={setState}
          >
            <option value="0">Seleccione una opción</option>
            {listUniversities.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            sx={{ m: 1 }}
            size="small"
            label="Medios de Pago"
            id={paymentMethods.payment_methods}
            name="payment_methods"
            value={paymentMethods.payment_methods}
            select
            SelectProps={{
              native: true,
            }}
            onChange={setState}
          >
            <option value="0">Seleccione una opción</option>
            <option value="Efectivo">Efectivo</option>
            <option value="Transferencia">Transferencia</option>
            <option value="Online">En Linea</option>
          </TextField>
        </div>
        <div className="mr-10">
          <button onClick={()=>getPaymentReport()} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
            Buscar
          </button>
        </div>
        <div className="mr-10">
          <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
            <CSVLink data={data} headers={headers_report} filename={"Reporte.csv"} style={{color: 'white', width: '10%'}}>Descargar Excel</CSVLink>
          </button>
        </div>
        {message.status ? (
          <Alert className="mt-2" severity={message.label}>
            {message.message}
          </Alert>
        ) : (
          ""
        )}
      </div>

      <div className="flex">
          <h3>Pagos: </h3>
          <h1 className="mr-10 ml-1 font-bold">${total}</h1>
          <h3>Transferencia: </h3>
          <h1 className="mr-10 ml-1 font-bold">${transfer}</h1>
          <h3>Efectivo: </h3>
          <h1 className="mr-10 ml-1 font-bold">${cash}</h1>
          <h3>Online: </h3>
          <h1 className="mr-10 ml-1 font-bold">${online}</h1>
          <div className="mr-10 flex-1 flex justify-end ">
            <button onClick={()=>getPaymentReportFourMonth()} className=" justify-end px-4 py-2 mb-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
              Obtener deudores
            </button>
        </div>
      </div>
      {
        debts ?
          <div className="flex-1 mb-10">
            <p className=" text-lg font-bold mb-2">Deudores por cuatrimestres:</p>
              <div className=" bg-white p-10 rounded-lg">
                {
                  listDebtsFourMonth ?
                  listDebtsFourMonth.map((item, index)=>(
                    <>
                      <p className="font-bold mt-10 mb-2">CUATRIMESTRE {index}</p>
                      <div>
                        {
                          item.map(name=>(
                            <p className=" text-sm ">{name.name.toUpperCase()}</p>
                          ))
                        }
                      </div>
                    </>
                  ))
                  : null
                }
              </div>
          </div>
        : null
      }
      {
        ! debts ?
        <div className="flex-1 mb-10">
          <TableHistoryCustom fields={FieldsCustomTable} data={data} />
        </div>
        : null
      }
    </div>
  );
};

export default Report;
