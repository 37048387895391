import React from "react";
import { Page, Text, View, Document, Image, StyleSheet } from "@react-pdf/renderer";

import Logo from "./logo_iumv.png";

const PDFReceipt = ({ name, curp, description, item }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 9,
      paddingTop: 30,
      lineHeight: 1.5,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
    logo: {
      display: "block",
      position: "absolute",
      width: "40%",
      resizeMode: "contain",
      top: -90,
    },
    item: {
      marginTop: 5,
    },
    title: {
      fontWeight: "bold",
    },
  });

  const getTotal = ( item ) => {
    let total = 0;
    let surcharge = 0;
    let amount = 0;
    let discount = 0;

    if(item.amount){
      amount = parseFloat(item.amount);
    }
    if(item.surcharge){
      surcharge = parseFloat(item.surcharge);
    }
    if(item.discount){
      discount = parseFloat(item.discount);
    }

    return total = (amount + surcharge - discount).toFixed(2)


  }

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.item}>
          <View style={styles.item}>
            <Image src={Logo} style={styles.logo} />
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>Nombre del Alumno:</Text>
            <Text>{name}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>CURP:</Text>
            <Text>{curp}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>Folio:</Text>
            <Text>{item.code}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>Forma de pago:</Text>
            <Text>{item.method_payment}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.title}>Fecha de pago:</Text>
            <Text>{item.register_date}</Text>
          </View>

          <View style={styles.item}>
            <Text style={styles.title}>Concepto:</Text>
            <Text style={{ textTransform:'uppercase' }}>{description}</Text>
          </View>

          <View style={styles.item}>
            <Text style={styles.title}>Importe:</Text>
            <Text>${item.amount ? item.amount : item.monthly_payment}</Text>
          </View>

          <View style={styles.item}>
            <Text style={styles.title}>Descuento:</Text>
            <Text>${item.discount ? item.discount : item.discount}</Text>
          </View>

          {item.paid_status === "surchargePaid" ? (
            <View style={styles.item}>
              <Text style={styles.title}>Recargo:</Text>
              <Text >${item.surcharge ? item.surcharge : item.surcharge_payment}</Text>
            </View>
          ) : null}

          <View style={styles.item}>
            <Text style={styles.title}>TOTAL:</Text>
            <Text>{ getTotal(item) }</Text>
          </View>

        </View>
      </Page>
    </Document>
  );
};

export default PDFReceipt;
