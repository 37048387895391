import React, { useState, useEffect } from "react";
import { updateItem, getData } from "../Tools/helpers";
import Alert from "@mui/material/Alert";

import TableHistoryCustom from "../components/TableHistoryCustom";
import DialogCustom from "../components/DialogCustom";

const RequestPaymentTransfer = () => {
  const [userData] = useState(JSON.parse(localStorage.getItem("USER_IUMV")));
  const [data, setData] = useState([]);
  const [itemApproved, setItemApproved] = useState(0);
  const [isDialog, setIsDialog] = useState(false);
  const [isDialog2, setIsDialog2] = useState(false);
  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const FieldsCustomTable = [
    { name: "code", label: "Código" },
    { name: "name_student", label: "Nombre" },
    { name: "campus", label: "Campus" },
    { name: "description", label: "Concepto" },
    { name: "month", label: "Mes" },
    { name: "transfer_date", label: "Fecha" },
    { name: "total", label: "Total" },
    { name: "method_payment", label: "Forma de pago" },
    { name: "status_description", label: "Estatus" },
    { name: "approved_by", label: "Apropado por" },
    { name: "approved_date", label: "Fecha aprobación" },
    { name: "status", label: "" },
  ];

  const getPayments = async () => {
    const route = "PaymentTransfer";
    const send = await getData(route);
    if (send.code === 200) {
      setData(send.data);
    }
  };

  const approvedPayment = async (option, statusPayment) => {
    setMessage({
      status: false,
      message: "",
      label: "",
    });

    if(statusPayment === 0){
      setIsDialog(false);
    }else{
      setIsDialog2(false);
    }

    if (!option) {
      return;
    }
    setMessage({
      status: true,
      message: "Cancelando...",
      label: "info",
    });

    const dataStatus = {
      status: statusPayment,
      status_description: statusPayment === 0 ? "Aprobado" : "Declinado",
    };

    const route = "ApprovePaymentFourMonth";
    const send = await updateItem(`${route}/${itemApproved.id}`, dataStatus);
    if (send.code === 200) {
      setMessage({
        status: true,
        message: statusPayment === 0 ? "Se ha aprobado correctamente" : "Se ha declinado, el alumno debe reenviar el comprobante",
        label: "success",
      });
      getPayments();
      return;
    }
    setMessage({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  };

  const downloadEvidence = async (item) => {
    const route = "DownloadEvidence";
    const send = await getData(`${route}/${item.id}`);
    if (send.code === 200) {
      var a = document.createElement("a");
      a.href = send.data;
      a.download = `${item.code}_${item.name_student}_${item.month}`;
      a.click();
    }
  };

  const activeDialog = (row, action) => {
    setItemApproved(row);
    if(action === 'aprobar'){
      setIsDialog(true);
      return;
    }
    setIsDialog2(true);
  };

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <div className="flex-1">
      <DialogCustom
        open={isDialog}
        message={{ title: "Aprobar", message: "¿Desea aprobar el pago?" }}
        action={approvedPayment}
        statusPayment = {0}
      />
      <DialogCustom
        open={isDialog2}
        message={{ title: "Declinar", message: "¿Desea declinar el pago?" }}
        action={approvedPayment}
        statusPayment = {2}
      />
      <div className="flex-1 mb-10">
        <div className="my-10">
          <p className="text-4xl underline">{ userData.abbreviation === 'cajero' ? 'Historial de últimos pagos' : 'Validar pagos Transferencia/Deposito' }</p>
        </div>
        {message.status ? (
          <Alert className="mt-2" severity={message.label}>
            {message.message}
          </Alert>
        ) : (
          ""
        )}
        <TableHistoryCustom
          fields={FieldsCustomTable}
          data={data}
          actionType={userData.abbreviation === 'cajero' ? ["downloadEvidence"] : ["approve", "downloadEvidence"]}
          action={activeDialog}
          action2={downloadEvidence}
        />
      </div>
    </div>
  );
};

export default RequestPaymentTransfer;
