import React, { useEffect, useState } from "react";
import {   
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement,   CategoryScale,
  LinearScale,
  BarElement,
  Title, Tooltip, Legend);

const AllFourMonth = ({ totalAllFourMont }) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    height: 300,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pagos/Adeudos Cuatrimestres',
      },
    },
    tooltips: {
      enabled: true, 
    },
  };

  const labels = totalAllFourMont ? totalAllFourMont.four_months : [];

  const dataEntryTotal = {
    labels,
    datasets: [
      totalAllFourMont.payments ? totalAllFourMont.payments : [],
      totalAllFourMont?.debts ? totalAllFourMont?.debts : [],
    ] 
  };

  return (
    <div className="p-10 h-[300px] col-span-3">
      <Bar options={options} data={dataEntryTotal} />
    </div>
  );
};

export default AllFourMonth;
