import React, { useState, useEffect } from "react";
import { getData } from "../Tools/helpers";

import TableHistoryCustom from "../components/TableHistoryCustom";

const PaymentHistory = () => {
  const [data, setData] = useState([]);

  const getPaymentHistory = async () => {
    const route = "Activity";
    const send = await getData(route);
    if (send.code === 200) {
      setData(send.data);
    }
  };

  const FieldsCustomTable = [
    { name: "desc_1", label: "Actividad" },
    { name: "module", label: "Modulo" },
    { name: "desc_2", label: "" },
    { name: "user", label: "Usuario" },
    { name: "created_at", label: "Fecha" },
  ];

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <div className="flex-1">
      <div className="my-10">
        <p className="text-4xl underline">Actividad</p>
      </div>
      <div className="flex-1 mb-10">
        <TableHistoryCustom fields={FieldsCustomTable} data={data} />
      </div>
    </div>
  );
};

export default PaymentHistory;
