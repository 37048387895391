import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../Tools/helpers";
import { setCookie } from "../Tools/AuthenticatedComponent";

const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { email, password } = user;

  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const setState = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const submitLogin = async (e) => {
    e.preventDefault();
    setMessage({
      status: true,
      message: "Iniciando...",
    });

    const sendUser = await login("login", user);
    if (sendUser.status === "success") {
      const user = JSON.stringify(sendUser.user);
      await localStorage.setItem("STORAGE_KEY_IUMV", sendUser.access_token);
      await localStorage.setItem("USER_IUMV", user);
      setMessage({
        status: false,
        message: "",
      });
      navigate("/Home");
      return;
    }
    setMessage({
      status: true,
      message: "Credenciales incorrectas",
    });
  };

  const logout = async () => {
    await localStorage.removeItem("STORAGE_KEY_IUMV");
    await localStorage.removeItem("USER_IUMV");
  };

  useEffect(() => {
    logout();
  }, []);

  
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
        <div className="flex text-center content-center justify-center justify-items-center">
          <img src="logo_iumv.png" alt="IUMV" />
        </div>
        <h1 className="text-3xl font-semibold text-center text-red-700">Bienvenido a tu portal</h1>
        <form className="mt-6" onSubmit={submitLogin}>
          <div className="mb-2">
            <label htmlFor="email" className="block text-sm font-semibold text-gray-800">
              Correo:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={setState}
              className="block w-full px-4 py-2 mt-2 text-red-700 bg-white border rounded-md focus:border-red-400 focus:ring-red-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="password" className="block text-sm font-semibold text-gray-800">
              Contraseña
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={setState}
              className="block w-full px-4 py-2 mt-2 text-red-700 bg-white border rounded-md focus:border-red-400 focus:ring-red-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mt-6">
            <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
              Entrar
            </button>
          </div>
          <div className="">
            {message.status ? (
              <p className="mt-2 peer-invalid:visible text-pink-600 text-sm">{message.message}</p>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
