import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import imageCompression from 'browser-image-compression';

import IOSSwitch from "./IOSSwitch";
import { insertDataJson, updateItem } from "../../Tools/helpers";
import { PDFDownloadLink } from "@react-pdf/renderer";

import PaymentCard from "./PaymentCard";
import PaymentTransfer from "./PaymentTransfer";
import PDFReceipt from "../../Tools/PDFReceipt";
import PaymentOnline from "./PaymentOnline";

const PaymentCashModule = ({ infoPayment, setInfoPayment, refreshList, studentData }) => {
  const [isBilled, setIsBilled] = useState(false);
  const [userData] = useState(JSON.parse(localStorage.getItem("USER_IUMV")));
  const [isPaidStatus, setIsPaidStatus] = useState(false);
  const [metodoPago, setMetodoPago] = useState('transfer');
  const [infoBill, setInfoBill] = useState({
    business_name: "",
    rfc: "",
    cfdi: "",
    bill: "",
  });

  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const setState = (e) => {
    setInfoBill({
      ...infoBill,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = async (event) => {
    setMessage({
      status: true,
      message: "Comprimiendo...",
    });
    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      console.log(compressedFile);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile); 
      reader.onloadend = function() {
        const imgBase64data = reader.result;                
        console.log(imgBase64data);
        setInfoPayment({
          ...infoPayment,
          evidence: imgBase64data,
        });
        setMessage({
          status: false,
          message: "",
        });
    }

    } catch (error) {
      console.log(error);
    }
  }

  const handleFile_ = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    const maxSizeFile = event.target.files[0].size;
    setMessage({
      status: false,
      message: "",
    });
    if (maxSizeFile > 3000000) {
      setMessage({
        status: true,
        label: "error",
        message: "El archivo debe pesar menos de 3M",
      });
      return;
    }
    reader.onload = function () {
      console.log(reader.result);
      setInfoPayment({
        ...infoPayment,
        evidence: reader.result,
      });
    };
  };

  const PaymentMercadoPago = async () => {
    const route = "crear-preferencia-pago";
    const send = await insertDataJson(route, infoPayment);
    
    if (send.status === 'error') {
      if(send.errors){
        setMessage({
          status: true,
          label: "error",
          message: send.errors,
        });
        return;        
      }
      setMessage({
        status: true,
        label: "error",
        message: "No se realizó la",
      });
      return;
    }
    openPaymentPopup(send.data.sandbox_init_point);
  };

  const openPaymentPopup = (url) => {
    //return;
    window.location.href = url;
    return;

    // const width = window.innerWidth;
    // const height = 400;
    // const left = window.innerWidth / 2 - width / 2;
    // const top = window.innerHeight / 2 - height / 2;

    // const popupWindow = window.open(
    //   url, 
    //   'Pago en Mercado Pago',
    //   `width=${width},height=${height},left=${left},top=${top}`
    // );
  }

  useEffect(() => {
    if (infoPayment.paid_status === "paid" || infoPayment.paid_status === "surchargePaid") {
      setIsPaidStatus(true);
    } else {
      setIsPaidStatus(false);
    }

    // if (infoPayment.you_can_pay === "false") {
    //   setMessage({
    //     status: true,
    //     message: "Debe pagar primero los meses anteriores",
    //     label: "warning",
    //   });
    // } else if (infoPayment.you_can_pay === "true") {
    //   setMessage({
    //     status: false,
    //     message: "",
    //     label: "",
    //   });
    // }

  }, [infoPayment]);

  const savePaymentStudent = async (action) => {
    setMessage({
      status: false,
      message: "",
      label: "",
    });

    if(infoPayment.amount === "" || infoPayment.amount === 0){
      setMessage({
        status: true,
        message: "Capture un monto válido",
        label: "error",
      });
      return;
    }

    if (
      isBilled &&
      (infoBill.length === 0 ||
        infoBill.business_name === "" ||
        infoBill.rfc === "" ||
        !infoBill.cfdi ||
        !infoBill.business_name ||
        !infoBill.rfc ||
        !infoBill.cfdi)
    ) {
      setMessage({
        status: true,
        message: "Los campos de facturación son requeridos",
        label: "error",
      });
      return;
    }

    if (infoPayment.method_payment === "Transferencia" && metodoPago === 'transfer') {
      if (
        infoPayment.folio_transfer === "" ||
        infoPayment.transfer_date === "" ||
        infoPayment.evidence === ""
      ) {
        setMessage({
          status: true,
          message: "Los campos de transferencia son requeridos",
          label: "error",
        });
        return;
      }
    }

    if (infoPayment.email_student === "" || !infoPayment.email_student) {
      setMessage({
        status: true,
        message: "El correo electrónico es requerido",
        label: "error",
      });
      return;
    }

    setMessage({
      status: true,
      message: "Guardando...",
      label: "info",
    });

    infoPayment["info_bill"] = infoBill;

    if(metodoPago === 'online'){
      PaymentMercadoPago();
      return;
    }

    const route = "PaymentFourMonth";
    let send = "";
    if (action === "pagar") {
      send = await insertDataJson(route, infoPayment);
    } else if(action === "reenviar"){
      send = await updateItem(
        `UpdatePaymentTransfer/${infoPayment.payment_four_month_id}`,
        infoPayment
      );
    }
    else{
      send = await updateItem(
        `${route}/${infoPayment.info_bill.payment_four_month_id}`,
        infoPayment
      );
    }

    if (send.code === 201 || send.code === 200) {
      setMessage({
        status: true,
        message: "Se ha guardado correctamente",
        label: "success",
      });
      setIsPaidStatus(true);
      refreshList(infoPayment.year);
      setInfoBill({
        business_name: "",
        rfc: "",
        cfdi: "",
        bill: "",
      });
      return;
    }
    if (send.code === 400) {
      setMessage({
        status: true,
        message: "Ya se ha registrado este pago",
        label: "error",
      });
      return;
    }
    setMessage({
      status: true,
      message: "Ha ocurrido algo, vuelva a seleccionar el mes",
      label: "error",
    });
  };

  const downloadEvidence = (evidence, code, description) => {
    var a = document.createElement("a");
    a.href = evidence;
    a.download = `${code}_${studentData.name}_${description}`;
    a.click();
  };

  return (
    <div className="flex justify-center p-8">
      <div className="w-full">
        <p className="font-bold">Datos Bancarios</p>
        <p className="mb-5">
          (Pago en {infoPayment.method_payment})
          {infoPayment.evidence !== "" ? (
            <a
              href="#"
              onClick={() =>
                downloadEvidence(infoPayment.evidence, infoPayment.code, infoPayment.description)
              }
              target="_blank"
            >
              <li className="ml-2 cursor-pointer fa-solid fa-file-arrow-down text-xl text-green-500"></li>
            </a>
          ) : null}
        </p>
        {/* <div className="flex justify-end items-center">
          <label className="pr-5">Facturar</label>{" "}
          <IOSSwitch onChange={(e) => setIsBilled(e.target.checked)} color="success" />
        </div> */}
        <div>
          <p className="font-bold">Concepto:</p>
          <p className="ml-5 text-sm">
            Descripción: <b>{infoPayment.description}</b>
          </p>
          <p className="ml-5 text-sm">
            Año: <b>{infoPayment.year}</b>
          </p>
          <p className="ml-5 text-sm">
            Cuatrimestre: <b>{infoPayment.four_month}</b>
          </p>
          <p className="ml-5 text-sm">
            Mes: <b>{infoPayment.month}</b>
          </p>
          <p className="ml-5 text-sm">
            Importe: 
            {/* <b>${infoPayment.amount ? parseFloat(infoPayment.amount).toFixed(2) : ""}</b> */}
            <input
                style={{width: 100, padding: 5, marginLeft: 10}}
                name="amount"
                value={infoPayment.amount}
                onChange={(e)=>{
                  setInfoPayment({
                    ...infoPayment,
                    [e.target.name]: e.target.value,
                  })
                }}
                className="border w-full p-1"
              />
          </p>
          {infoPayment.paid_status === "surchargePaid" ||  infoPayment.paid_status === "surcharge" ? (
            <p className="ml-5 text-sm">
              Recargo: 
              {/* <b>${parseFloat(infoPayment.surcharge).toFixed(2)}</b> */}
              <input
                style={{width: 100, padding: 5, marginLeft: 10}}
                name="surcharge"
                value={infoPayment.surcharge}
                onChange={(e)=>{
                  setInfoPayment({
                    ...infoPayment,
                    [e.target.name]: e.target.value,
                  })
                }}
                className="border w-full p-1"
              /> 
            </p>
          ) : null}
          {
            infoPayment.numberOfDays && parseInt(infoPayment.numberOfDays) !== 0 ?
              <small className="ml-5 text-red-600">Día Límite: {infoPayment.payday_limit}, Días atraso: ({infoPayment.numberOfDays}) </small>
            : null
          }
          <p className="ml-5 text-sm">
            Descuento: 
            {/* <b>${infoPayment.amount ? parseFloat(infoPayment.amount).toFixed(2) : ""}</b> */}
            <input
                style={{width: 100, padding: 5, marginLeft: 10}}
                name="discount"
                value={infoPayment.discount}
                onChange={(e)=>{
                  setInfoPayment({
                    ...infoPayment,
                    [e.target.name]: e.target.value,
                  })
                }}
                className="border w-full p-1"
              />
          </p>
          <p className="ml-5 text-sm">
            TOTAL:{" "}
            <b className="text-lg">
              $
              {infoPayment.amount
                ? ((parseFloat(infoPayment.amount) + parseFloat(infoPayment.surcharge ==="" ? 0 : parseFloat(infoPayment.surcharge))) - ( infoPayment.discount ==="" ? 0 : parseFloat(infoPayment.discount))  ).toFixed(2)
                : ""}
            </b>
          </p>
        </div>
        {userData.abbreviation === "student" ? (
          <>
            <h5 className="mt-5"><b>¿Cómo desea pagar?</b></h5>
            <button onClick={()=>setMetodoPago('transfer')} className={ metodoPago === 'transfer' ? 'px-10 py-2  bg-sky-700 rounded-lg text-white font-bold' : 'px-10 py-2  bg-slate-400 rounded-lg text-white font-bold' }>Transferencia</button>
            {/* <button onClick={()=>setMetodoPago('online')} className={ metodoPago === 'online' ? 'px-10 py-2  bg-sky-700 rounded-lg text-white font-bold' : 'px-10 py-2  bg-slate-400 rounded-lg text-white font-bold' }>Pago en línea</button> */}
            {/* <PaymentCard setInfoPayment = {setInfoPayment} infoPayment = {infoPayment} /> */}
            {
               metodoPago === 'transfer' ?
                <PaymentTransfer
                  setInfoPayment={setInfoPayment}
                  infoPayment={infoPayment}
                  handleFile={handleFile}
                />
               : <h5 className="mt-5">Débito o Crédito a través de Mercado Pago</h5>
            }
          </>
        ) : null}

        {isBilled ? (
          <div>
            <div className="mt-1">
              <p className="font-bold mt-10">Facturar:</p>
            </div>
            <div className="grid grid-cols-3">
              <div className="mt-5">
                <p className="ml-5 text-sm">Razón social:</p>
              </div>
              <div className="mt-5 col-span-2">
                <input
                  name="business_name"
                  value={infoBill.business_name ? infoBill.business_name : ""}
                  onChange={setState}
                  className="border w-full p-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="mt-1">
                <p className="ml-5 text-sm">RFC:</p>
              </div>
              <div className="mt-1 col-span-2">
                <input
                  name="rfc"
                  value={infoBill.rfc ? infoBill.rfc : ""}
                  onChange={setState}
                  className="border w-full p-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="mt-1">
                <p className="ml-5 text-sm">Uso CFDI:</p>
              </div>
              <div className="mt-1 col-span-2">
                <input
                  name="cfdi"
                  value={infoBill.cfdi ? infoBill.cfdi : ""}
                  onChange={setState}
                  className="border w-full p-1"
                />
              </div>
            </div>
            <div className="my-10 flex justify-end">
              {infoPayment.you_can_pay === "true" || infoPayment.you_can_pay  === "false" ? (
                !infoPayment.info_bill.bill === "" ||
                (!infoPayment.info_bill.bill && isPaidStatus) ? (
                  <button
                    onClick={() => savePaymentStudent("facturar")}
                    className="px-10 py-2 bg-sky-900 rounded-lg text-white font-bold"
                  >
                    FACTURAR
                  </button>
                ) : null
              ) : null}
            </div>
          </div>
        ) : null}
        <div>
          {message.status ? (
            <Alert className="mt-2" severity={message.label}>
              {message.message}
            </Alert>
          ) : (
            ""
          )}
        </div>
        <div className="grid mt-5 grid-cols-3">
          <div className="mt-1">
            <p className="ml-5 font-bold">Correo del alumno:</p>
          </div>
          <div className="mt-1 col-span-2">
            <input
              type="email"
              name="email_student"
              value={infoPayment.email_student ? infoPayment.email_student : ""}
              onChange={(e) => {
                setInfoPayment({
                  ...infoPayment,
                  [e.target.name]: e.target.value,
                });
              }}
              className="border w-full p-1"
            />
          </div>
        </div>
        <div className="my-10 flex justify-end">
        {
          infoPayment ?
            infoPayment.type === 'procedure' && isPaidStatus ?
              <PDFDownloadLink
              document={
                <PDFReceipt
                  name={studentData.name ? studentData.name : ""}
                  curp={studentData.curp ? studentData.curp : ""}
                  item={infoPayment}
                  description = { infoPayment.description }
                />
              }
              fileName={`Recibo_${infoPayment.description}`}
            >
              <button className="px-10 py-2 mr-5 bg-green-500 rounded-lg text-white font-bold">Descargar Recibo</button>
            </PDFDownloadLink>
            : null
          : null
        }

          {infoPayment.you_can_pay === "true" || infoPayment.you_can_pay  === "false" ? (

            isPaidStatus  ? 

              parseInt(infoPayment.status) === 2 ?
                (
                  <button
                  onClick={() => savePaymentStudent("reenviar")}
                  className="px-10 py-2 bg-sky-900 rounded-lg text-white font-bold"
                >
                    REENVIAR COMPROBANTE
                  </button>
                )
              :
                (
                <button className="px-10 py-2 bg-red-700 rounded-lg text-white font-bold">
                  {! infoPayment.info_bill && infoPayment.info_bill.bill !== null && infoPayment.info_bill.bill !== ""
                    ? "PAGADO Y FACTURADO"
                    : "PAGADO"}
                </button>
                ) 

            : (
              <button
                onClick={() => savePaymentStudent("pagar")}
                className="px-10 py-2 bg-sky-900 rounded-lg text-white font-bold"
              >
                PAGAR
              </button>
            )

          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PaymentCashModule;
