import React, { useEffect, useState } from "react";
import {   
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement,   CategoryScale,
  LinearScale,
  BarElement,
  Title, Tooltip, Legend);

const PaymentOptions = ({ paymentOptions }) => {
  const [ dataValues, setDataValues] = useState({});

  const options = {
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Montos (Opciones de pago)',
      },
    },
  };

  const colors = [
    'rgba(255, 99, 132)',
    'rgba(54, 162, 235)',
    'rgb(56 199 29)',
    'rgb(145 16 240)',
    'rgb(185 15 175)',
    'rgb(255 131 0)',
  ];

  useEffect(()=>{
    let labels = [];
    let values = [];
    let valueColors = [];
    let i = 0;
    if(paymentOptions){
      paymentOptions.map(item=>{
        labels.push(item.method_payment);
        values.push(item.total);
        valueColors.push(colors[i]);
        i++;
      });
    }
    
    setDataValues({
      labels: labels,
      values: values,
      colors: colors,
    });
  },[paymentOptions])

  const dataPaymentOptions = {
    labels: dataValues.labels,
    datasets: [
      {
        label: 'Monto',
        data: dataValues.values,
        backgroundColor: dataValues.colors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="p-10">
      <Doughnut options={options} data={dataPaymentOptions} />
    </div>
  );
};

export default PaymentOptions;
