import React from "react";
import {   
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement,   CategoryScale,
  LinearScale,
  BarElement,
  Title, Tooltip, Legend);

const AditionalEntry = ({ totalDischarge }) => {

  const options = {
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Entrega de Efectivo/Egresos',
      },
    },
  };

  const dataEntry = {
    labels: ['Entrega Efectivo', 'Egresos'],
    datasets: [
      {
        label: 'Monto',
        data: [totalDischarge.entry, totalDischarge.discharge],
        backgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(54, 162, 235)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="p-10 col-start-2 justify-center">
      <Doughnut options={options} data={dataEntry} />
    </div>
  );
};

export default AditionalEntry;
