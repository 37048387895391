import React from "react";
import {   
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement,   CategoryScale,
  LinearScale,
  BarElement,
  Title, Tooltip, Legend);

const Payment = ({ totalDebts }) => {

  const options = {
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pagos/Adeudos Totales',
      },
    },
  };

  const dataPayment = {
    labels: ['Pagos', 'Adeudos'],
    datasets: [
      {
        label: 'Monto',
        data: [totalDebts.payment, totalDebts.debt],
        backgroundColor: [
          'rgb(145 16 240)',
          'rgb(255 0 0)',
        ],
        borderWidth: 1,
      },
    ],
  };  

  return (
    <div className="p-10">
      <Doughnut options={options} data={dataPayment} />
    </div>
  );
};

export default Payment;
