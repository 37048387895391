import React, { useEffect, useState } from "react";
import {   
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement,   CategoryScale,
  LinearScale,
  BarElement,
  Title, Tooltip, Legend);

const AllCareers = ({ totalAllCareer }) => {
  const options = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    responsive: true,
    height: 300,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pagos/Adeudos Carreras',
      },
    },
  };

  const labels = totalAllCareer ? totalAllCareer.careers : [];

  const dataEntryTotal = {
    labels,
    datasets: [
      totalAllCareer.payments ? totalAllCareer.payments : [],
      totalAllCareer?.debts ? totalAllCareer?.debts : [],
    ] 
  };

  return (
    <div className="p-10 h-[600px] col-span-3">
      <Bar options={options} data={dataEntryTotal} />
    </div>
  );
};

export default AllCareers;
