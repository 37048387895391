import React, { useEffect, useState } from "react";
import { getData, searchTable } from "../Tools/helpers";

import TableListStudent from "./components/TableListStudent";

const Payment = () => {
  const [dataTable, setDataTable] = useState([]);

  const routeForm = "Student-university";

  const getStudents = async () => {
    const user = await JSON.parse(localStorage.getItem("USER_IUMV"));
    const route = routeForm;
    const send = await getData(`${route}/${user.university_id}`);
    console.log(send);
    if (send.code === 200) {
      setListSearch(send.data);
      setDataTable(send.data);
    }
  };

  //BUSQUEDA
  const [fieldSearch, setFieldSearch] = useState("");
  const [listSearch, setListSearch] = useState([]);

  const setStateSearch = async (e) => {
    console.log(e.target.value);
    await setFieldSearch(e.target.value);
    const resulData = await searchTable(dataTable, e.target.value, "name", "curp");
    setListSearch(resulData);
  };

  useEffect(() => {
    getStudents();
  }, []);

  const FieldsCustomTable = [
    { name: "student_id", label: "ID", required: "true" },
    { name: "name", label: "Nombre completo", required: "true" },
    { name: "email", label: "Correo", required: "true" },
    { name: "curp", label: "Curp", required: "true" },
    { name: "university_name", label: "Campus", required: "true" },
    { name: "career_name", label: "Carrera", required: "true" },
    { name: "start_date", label: "Fecha inicio", required: "false" },
    { name: "end_date", label: "Fecha Termino", required: "false" },
  ];

  return (
    <div className="flex-1">
      <div className="my-10">
        <p className="text-4xl underline">Pagos</p>
      </div>
      <div className="flex-1 mb-10">
        <p>Buscar</p>
        <label className="relative block">
          <span className="sr-only">Search</span>
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <i className="text-slate-400 fa-solid fa-magnifying-glass"></i>
          </span>
          <input
            className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="Buscar por nombre o curp..."
            type="text"
            name="search"
            value={fieldSearch}
            onChange={setStateSearch}
          />
        </label>
      </div>
      <div className="flex-1">
        <TableListStudent fields={FieldsCustomTable} data={listSearch} />
      </div>
    </div>
  );
};

export default Payment;
