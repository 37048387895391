import React, { useEffect, useState } from "react";
import { getData, searchTable } from "../../Tools/helpers";

import FormCustom from "../../components/FormCustom";
import TableCustom from "../../components/TableCustom";

const Student = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [isTrueUpdateData, setIsTrueUpdateData] = useState(false);
  const [isTrueDeleteData, setIsTrueDeleteData] = useState(false);

  const [listCareers, setListCareers] = useState([]);
  const [listUniversities, setListUniversities] = useState([]);
  const [listPermissions, setListPermissions] = useState([]);

  const FieldsCustom = [
    { name: "name", label: "Nombre completo", required: "true" },
    { name: "curp", label: "Curp", required: "true" },
    { name: "phone", label: "Teléfono", required: "false" },
    { name: "email", label: "Email", required: "true" },
    { name: "start_date", label: "Fecha de inicio", required: "false", type: "date" },
    {
      name: "university_id",
      label: "Campus",
      required: "true",
      type: "select",
      list: listUniversities,
      field_select_description: "name",
    },
    {
      name: "career_id",
      label: "Carrera",
      required: "true",
      type: "select",
      list: listCareers,
      field_select_description: "name",
    },
    { name: "password", label: "Contraseña", required: "true", type: "password" },
    {
      name: "permission_id",
      label: "Permiso de usuario",
      required: "true",
      type: "select",
      list: listPermissions,
      field_select_description: "description",
    },
    {
      name: "status",
      label: "Estatus",
      required: "true",
      type: "select",
      list: [
        {
          'id': 'Activo',
          'status': 'Activo',
        },
        {
          'id': 'Graduado',
          'status': 'Graduado',
        },
        {
          'id': 'Baja',
          'status': 'Baja',
        },
      ],
      field_select_description: "status",
    },
  ];

  const FieldsCustomTable = [
    { name: "name", label: "Nombre completo", required: "true" },
    { name: "curp", label: "Curp", required: "true" },
    { name: "phone", label: "Teléfono", required: "false" },
    { name: "email", label: "Email", required: "true" },
    { name: "career_name", label: "Carrera", required: "true" },
    { name: "university_name", label: "Campus", required: "true" },
    { name: "status", label: "Estatus", required: "true" },
    { name: "descripcion_permissions", label: "Permiso de usuario", required: "true" },
  ];

  const routeForm = "Student";

  const getStaff = async () => {
    const route = routeForm;
    const send = await getData(route);
    console.log(send);
    if (send.code === 200) {
      setDataTable(send.data);
      setListSearch(send.data);
    }
  };

    //BUSQUEDA
    const [fieldSearch, setFieldSearch] = useState("");
    const [listSearch, setListSearch] = useState([]);
  
    const setStateSearch = async (e) => {
      await setFieldSearch(e.target.value);
      const resulData = await searchTable(dataTable, e.target.value, "name", "curp", "email");
      setListSearch(resulData);
    };


  const getCareers = async () => {
    const route = "Career";
    const send = await getData(route);
    if (send.code === 200) {
      setListCareers(send.data);
    }
  };

  const getUniversities = async () => {
    const route = "University";
    const send = await getData(route);
    if (send.code === 200) {
      setListUniversities(send.data);
    }
  };

  const getPermissions = async () => {
    const route = "Permission";
    const send = await getData(route);
    if (send.code === 200) {
      const data = send.data.filter((item) => item.abbreviation === "student");
      setListPermissions(data);
    }
  };

  useEffect(() => {
    getStaff();
    getCareers();
    getUniversities();
    getPermissions();
  }, []);

  return (
    <div className="flex-1">
      <div className=" mb-1 max-w-2xl">
        <FormCustom
          fields={FieldsCustom}
          title="DATOS DE ESTUDIANTES"
          route={routeForm}
          method="post"
          setData={setDataForm}
          data={dataForm}
          fieldDialog="name"
          setIsTrueUpdateData={setIsTrueUpdateData}
          isTrueUpdateData={isTrueUpdateData}
          refreshDataList={getStaff}
          setIsTrueDeleteData={setIsTrueDeleteData}
          isTrueDeleteData={isTrueDeleteData}
        />
      </div>
      
      <div className="flex-1">
        <div className="pb-5">
          <input
              className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              placeholder="Burcar por nombre, curp o correo..."
              type="text"
              name="search"
              value={fieldSearch}
              onChange={setStateSearch}
            />
        </div>
        <TableCustom
          fields={FieldsCustomTable}
          setDataForm={setDataForm}
          data={listSearch}
          setIsTrueUpdateData={setIsTrueUpdateData}
          setIsTrueDeleteData={setIsTrueDeleteData}
        />
      </div>
    </div>
  );
};

export default Student;
