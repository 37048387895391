import React, { useState, useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from "@mui/material/Alert";
import { CSVLink } from "react-csv";
import { AppContext } from "../contextApp";
import { useParams } from "react-router-dom";
import { getData, insertDataJson } from "../Tools/helpers";

import TableHistoryCustom from "../components/TableHistoryCustom";

const PaymentHistory = () => {
  const { studentData, setStudentData } = useContext(AppContext);
  const [userData] = useState(JSON.parse(localStorage.getItem("USER_IUMV")));
  const [data, setData] = useState([]);
  const [dataPayment, setDataPayment] = useState([]);
  const [total, setTotal] = useState(0);
  const [amount, setAmount] = useState({
      id: 0,
      name: '',
      amount: 0,
      surcharge: 0,
      discount: 0,
      total: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [descriptionPayment, setDescriptionPayment] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [message, setMessage] = useState({
    status: false,
    message: "",
    label: "",
  });

  const { id } = useParams();
  const getStudent = async () => {
    const route = "Student";
    const send = await getData(`${route}/${id}`);
    if (send.code === 200) {
      setStudentData(send.data);
    }
  };

  useEffect(()=>{
    setTotalAmount(parseFloat(amount.amount === '' ? 0 : amount.amount) + parseFloat(amount.surcharge === '' ? 0 : amount.surcharge) - parseFloat(amount.discount === '' ? 0 : amount.discount))
  },[amount])

  const getPaymentHistory = async () => {
    const route = "PaymentFourMonth";
    const send = await getData(`${route}/${id}`);

    if (send.code === 200) {
      setData(send.data);
    }
  };

  const showModalEdit = (item) =>{
    setShowModal(true);
    setDescriptionPayment(`${item.description} (${item.month_description})`);
    setAmount({ 
        id: item.id,
        name: studentData.name,
        amount: item.amount ?? 0,
        surcharge: item.surcharge ?? 0,
        discount: item.discount ?? 0,
    });
  }

  const updateAmount = async () =>{
    setShowModal(false);
    console.log(amount);
    const route = "UpdatePayment";
    const send = await insertDataJson(`${route}/${amount.id}`, amount);

    if (send.code === 200) {
      getPaymentHistory();
      return;
    }
  }

  const setState = (e) => {
    setAmount({
      ...amount,
      [e.target.name]: e.target.value,
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const FieldsCustomTable = [
    { name: "code", label: "Folio" },
    { name: "description", label: "Concepto" },
    { name: "month_description", label: "Mes" },
    { name: "year", label: "Año" },
    { name: "amount", label: "Costo" },
    { name: "surcharge", label: "Recargo" },
    { name: "discount", label: "Descuento" },
    { name: "total", label: "Total" },
    { name: "user_name", label: "Cobró" },
    { name: "register_date", label: "Fecha" },
    { name: "method_payment", label: "Forma de pago" },
  ];

  const FieldsCustomTableDebt = [
    { name: "description", label: "Concepto" },
    { name: "month_description", label: "Mes" },
    { name: "year", label: "Año" },
    { name: "amount", label: "Costo" },
    { name: "surcharge", label: "Recargo" },
    { name: "total", label: "Total" },
  ];

  const getPaymentFourMonth = async (year) => {
    let newDataArray = [];
    const params = {
      year: year,
      student_id: id,
    };
    const route = "PaymentFourMonth";
    const send = await insertDataJson(`${route}/${id}`, params);
    let newData = {};
    if (send.code === 200 && send.data) {
      send.data.map(month=>{
        
        if(month.inscription) {
          if(month.inscription.paid_status === "surcharge"){
            newData = {
              description: month.inscription.description,
              month_description: month.description,
              amount: month.monthly_payment,
              surcharge: month.surcharge_payment,
              total: parseFloat(month.monthly_payment) + parseFloat(month.surcharge_payment),
              year: year
            }
            newDataArray.push(newData);
          }
          if(month.paid_status === "surcharge"){
            newData = {
              description: month.description_payment,
              month_description: month.description,
              amount: month.monthly_payment,
              surcharge: month.surcharge_payment,
              total: parseFloat(month.monthly_payment) + parseFloat(month.surcharge_payment),
              year: year
            }
            newDataArray.push(newData);
          }
        }
        else{
          if(month.paid_status === "surcharge"){
            newData = {
              description: month.description_payment,
              month_description: month.description,
              amount: month.monthly_payment,
              surcharge: month.surcharge_payment,
              total: parseFloat(month.monthly_payment) + parseFloat(month.surcharge_payment),
              year: year
            }
            newDataArray.push(newData);
          }
        }
      
      });
      return newDataArray;
    }
  };

  const toListYear = async () => {
    setMessage({
      status: true,
      message: "Calculando...",
      label: "info",
    });
    let newDataArray2 = [];
    const actualYear = new Date().getFullYear();
    for (let year = actualYear - 5; year <= actualYear + 2; year++) {
      const newArray = await getPaymentFourMonth(year.toString());
      if(newArray){
        newDataArray2 =  [...newDataArray2, ...newArray];
      }
    }
    const subtotal = newDataArray2.reduce((accumulator, actual) => accumulator + parseFloat(actual.amount ? actual.amount: 0), 0);
    const surcharge = newDataArray2.reduce((accumulator, actual) => accumulator + parseFloat(actual.surcharge ? actual.surcharge : 0), 0);
    const total = subtotal+surcharge;
    setTotal(total);
    setDataPayment(newDataArray2);
    setMessage({
      status: false,
      message: "",
      label: "",
    });
  };

  useEffect(() => {
    getStudent();
    getPaymentHistory();
    toListYear();
  }, []);

  const downloadEvidence = (item) => {
    const evidence = data.filter(evidence=> parseInt(evidence.code) === parseInt(item.code))[0];
    var a = document.createElement("a");
    a.href = evidence.evidence;
    a.download = `${evidence.code}_${evidence.name_student}_${evidence.month}`;
    a.click();
  };

  const headersCompletedPayment = [
    { key: "code", label: "Folio" },
    { key: "description", label: "Concepto" },
    { key: "month_description", label: "Mes" },
    { key: "year", label: "Año" },
    { key: "amount", label: "Costo" },
    { key: "surcharge", label: "Recargo" },
    { key: "discount", label: "Descuento" },
    { key: "total", label: "Total" },
    { key: "register_date", label: "Fecha" },
    { key: "method_payment", label: "Forma de pago" },
  ];

  const heardersDebtPayments = [
    { key: "description", label: "Concepto" },
    { key: "month_description", label: "Mes" },
    { key: "year", label: "Año" },
    { key: "amount", label: "Costo" },
    { key: "surcharge", label: "Recargo" },
    { key: "total", label: "Total" },
  ];

  return (
    <div className="flex-1">
      <Modal
        open={showModal}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edición de pago
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 2 }}>
            {descriptionPayment}
          </Typography>
          <TextField
              fullWidth
              sx={{ m: 1 }}
              name="amount"
              type="number"
              size="small"
              label="Costo"
              InputLabelProps={{  shrink: true }}
              value={amount.amount}
              variant="outlined"
              onChange={setState}
            />
          <TextField
              fullWidth
              sx={{ m: 1 }}
              name="surcharge"
              type="number"
              size="small"
              label="Recargo"
              InputLabelProps={{  shrink: true }}
              value={amount.surcharge}
              variant="outlined"
              onChange={setState}
            />
          <TextField
              fullWidth
              sx={{ m: 1 }}
              name="discount"
              type="number"
              size="small"
              label="Descuento"
              InputLabelProps={{  shrink: true }}
              value={amount.discount}
              variant="outlined"
              onChange={setState}
            />
            TOTAL: { totalAmount }
          <div className="mt-6">
            <button onClick={()=>updateAmount()} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
              Actualizar
            </button>
          </div>
          <div className="mt-6">
            <button onClick={()=>setShowModal(false)} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-sky-900 rounded-md hover:bg-sky-700">
              Cerrar
            </button>
          </div>
        </Box>
      </Modal>
      <div className="flex-1 mb-10">
          <div className="flex">
            <h1 className="mr-10 ml-1 font-bold">Pagos pendientes:</h1>
            <h2 className="text-xl">Total: </h2>
            <h1 className="mr-10 ml-1 font-bold text-xl">${total}</h1>
            <button className="px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
              <CSVLink data={dataPayment} headers={heardersDebtPayments} filename={`PagosPendientes_${studentData.name}.csv`} style={{color: 'white', width: '10%'}}>Descargar Excel</CSVLink>
            </button>
          </div>
          {message.status ? (
          <Alert className="mt-2" severity={message.label}>
            {message.message}
          </Alert>
          ) : (
            ""
          )}
        <TableHistoryCustom 
          fields={FieldsCustomTableDebt} 
          data={dataPayment} 
        />
      </div>
      <div className="flex-1 mb-10">
        <div className="flex">
            <h1 className="mr-10 ml-1 font-bold">Pagos realizados:</h1>
            <button className="px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">
              <CSVLink data={data} headers={headersCompletedPayment} filename={`PagosRealizados_${studentData.name}.csv`} style={{color: 'white', width: '10%'}}>Descargar Excel</CSVLink>
            </button>
          </div>
        <TableHistoryCustom 
          fields={FieldsCustomTable} 
          data={data} 
          actionType={userData.abbreviation === 'director_general' ? ["receipt", "downloadEvidence", "edit"] : ["receipt", "downloadEvidence"]}
          action2={downloadEvidence}
          action3={showModalEdit}
        />
      </div>
    </div>
  );
};

export default PaymentHistory;
