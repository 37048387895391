import React from "react";
import {   
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend);

const Student = ({ totalStudents }) => {

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    height: 100,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Total de alumnos',
      },
    },
  };

const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const data = {
  labels,
  datasets: [
    {
      label: 'Total',
      data: totalStudents.total,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
    {
      label: 'Nuevo Ingreso',
      data: totalStudents.ingreso,
      borderColor: 'rgb(114, 22, 148)',
      backgroundColor: 'rgba(114, 22, 148)',
    },
    {
      label: 'Egresados',
      data: totalStudents.graduado,
      borderColor: 'rgb(47, 188, 42)',
      backgroundColor: 'rgba(47, 188, 42)',
    },
  ],
};

  return (
    <div className="p-10 col-span-3">
      <Line className="w-full" options={options} data={data} />
    </div>
  );
};

export default Student;
